<template>
  <b-container fluid="md" :class="[isMobile ? 'mt-5 pt-3' : 'mt-3' ]">
    <b-row>
        <b-col v-if="!ready">
                <h1>{{searchMsg}}</h1>
        </b-col>
        <b-col v-else>
            <!-- Name -->
            <search-by-name v-if="nameSearch.length > 0" :searchTerm="searchTerm" :nameSearch="nameSearch"></search-by-name>
            <!-- Address -->
            <search-by-site-address v-if="addressSearch.length > 0" :searchTerm="searchTerm" :addressSearch="addressSearch"></search-by-site-address>
            <!-- phone -->
            <search-by-phone v-if="phoneSearch.length > 0" :searchTerm="searchTerm" :phoneSearch="phoneSearch"></search-by-phone>
            <!-- email -->
            <search-by-email v-if="emailSearch.length > 0" :searchTerm="searchTerm" :emailSearch="emailSearch"></search-by-email>
            <!-- home Model -->
            <search-by-home-model v-if="homeModelSearch.length > 0" :searchTerm="searchTerm" :homeModelSearch="homeModelSearch"></search-by-home-model>
            <!-- Color -->
            <search-by-color v-if="colorSearch.length > 0" :searchTerm="searchTerm" :colorSearch="colorSearch"></search-by-color>
            <!-- Materials -->
            <search-by-material v-if="materialSearch.length > 0" :searchTerm="searchTerm" :materialSearch="materialSearch"></search-by-material>
        </b-col>
    </b-row>
  </b-container>

</template>

<script>
import { format } from 'date-fns'
import { fromUnixTime } from 'date-fns'
import SearchByName from '../components/search-components/searchByName.vue'
import SearchBySiteAddress from '../components/search-components/searchBySiteAddress.vue'
import SearchByPhone from '../components/search-components/searchByPhone.vue'
import SearchByEmail from '../components/search-components/searchByEmail.vue'
import SearchByHomeModel from '../components/search-components/searchByHomeModel.vue'
import SearchByColor from '../components/search-components/searchByColor.vue'
import SearchByMaterial from '../components/search-components/searchByMaterial.vue'

export default {
  name: "search",
  components: {
    SearchByName,
    SearchBySiteAddress,
    SearchByPhone,
    SearchByEmail,
    SearchByHomeModel,
    SearchByColor,
    SearchByMaterial
  },
  data() {
    return {
        ready: false,
        nameSearch: [],
        addressSearch: [],
        phoneSeach: [],
        emailSearch: [],
        homeModelSearch: [],
        colorSearch: [],
        materialSearch: [],
        format,
        fromUnixTime,
        searchMsg: "Searching..."
    }
  },
  computed: {
    searchTerm () {
      return this.$state.searchTerm
    },
    isMobile() {
      return this.$state.isMobile
    }  
  },
  watch: {
    searchTerm (newSearch, oldSearch) {
      console.log(`SearchTerm updated to: ${newSearch}. It was: ${oldSearch}`)
      this.search()
    }
  },
  methods: {
    search () {
      this.nameSearch = []
      this.addressSearch = []
      this.phoneSearch = []
      this.emailSearch = []
      this.homeModelSearch = []
      this.colorsSearch = []
      this.materialSearch = []

      const phoneNumber = this.searchTerm.match(/^\d{3}-\d{3}-\d{4}$/)
      const contractNumber = this.searchTerm.match(/\b\d{5}\b/g)

      if(phoneNumber) {
        console.log("Its a phone number")
        console.log(phoneNumber)
        this.searchPhones()
      } else if(contractNumber){
        console.log("Its a contract number")
        this.searchContract()
      } else {
        this.searchNames()
        this.searchAddresses()
        this.searchEmails()
        this.searchHomeModel()
        this.searchColors()
        this.searchMaterials()
      }
      
      
      // ^\d{3}-\d{3}-\d{4}$ <-- Phone number 10digit with hyphens - regEx

    },
    async searchNames(){
      try {
        console.log("calling name search...")
        let res = await this.$axios.get(`/customer/search/name/${this.searchTerm}`, {
            headers: {
                Authorization: `Bearer ${this.$state.token}`
            }
        })

        this.nameSearch = res.data
        this.ready = true          

      } catch (err) {
        console.error(err)
      }
    },
    
    async searchAddresses() {
      try {
        console.log("Calling job search...")
        let res = await this.$axios.get(`/job/search/${this.searchTerm}`, {
            headers: {
                Authorization: `Bearer ${this.$state.token}`
            }
        })

        this.addressSearch = res.data
        this.ready = true

      } catch (err) {
        console.error(err)
      }
    }, 

    async searchPhones() {
      try {
        console.log("Calling customer phones search...")
        let res = await this.$axios.get(`/customer/search/phone/${this.searchTerm}`, {
            headers: {
                Authorization: `Bearer ${this.$state.token}`
            }
        })

        //console.log(res.data)
        this.phoneSearch = res.data
        this.ready = true

      } catch (err) {
        console.error(err)
      }
    },
    async searchEmails() {
      try {
        console.log("Calling customer emails search...")
        let res = await this.$axios.get(`/customer/search/email/${this.searchTerm}`, {
            headers: {
                Authorization: `Bearer ${this.$state.token}`
            }
        })

        //console.log(res.data)
        this.emailSearch = res.data
        this.ready = true

      } catch (err) {
        console.error(err)
      }
    },
    async searchContract() {
      try {
        console.log(`Finding jobId for contractId...${this.searchTerm}`)
        let res = await this.$axios.get(`/job/search/contractid/${this.searchTerm}`, {
            headers: {
                Authorization: `Bearer ${this.$state.token}`
            }
        })

        let jobId = res.data
        this.$router.push({ name: "jobProfile", params: { id: jobId } })

      } catch (err) {
        console.log(err)
      }  
    },
    async searchHomeModel() {
      try {
        console.log("Calling homeModel search...")
        let res = await this.$axios.get(`/job/search/homeModel/${this.searchTerm}`, {
            headers: {
                Authorization: `Bearer ${this.$state.token}`
            }
        })

        this.homeModelSearch = res.data

      } catch (err) {
        console.error(err)
      }
    },
    async searchColors() {
      try {
        console.log("Calling color search...")
        let res = await this.$axios.get(`/jobsetup/search/color/${this.searchTerm}`, {
            headers: {
                Authorization: `Bearer ${this.$state.token}`
            }
        })

        this.colorSearch = res.data

      } catch (err) {
        console.error(err)
      }
    },
    async searchMaterials() {
      try {
        console.log("Calling material search...")
        let res = await this.$axios.get(`/jobsetup/search/material/${this.searchTerm}`, {
            headers: {
                Authorization: `Bearer ${this.$state.token}`
            }
        })

        this.materialSearch = res.data

      } catch (err) {
        console.error(err)
      }
    }
  },
  created () {
        console.log(`searchTerm is: ${this.searchTerm}`)
        this.search()
        
        /*
        let logObj = {
            user: this.username, 
            msg: `Contract ${this.contractId} viewed by ${this.username}`, 
            contractId: this.contractId
        }
        this.$root.log(logObj)
        */
  }
}
</script>


<style lang="scss" scoped>
   
</style>
