<template>
    <b-card class="mb-3" no-body border-variant="primary" header-bg-variant="white" header-text-variant="primary">
        <template #header>
            <b-form inline>
                <h6 class="mb-0 d-inline">Materials like "{{searchTerm}}"</h6>
                <b-form-checkbox class="d-inline ml-2" v-model="showRoofing" name="showRoofingSwitch" switch >
                    Show Roofing 
                </b-form-checkbox>
                <b-form-checkbox class="d-inline ml-2" v-model="showSiding" name="showSidingSwitch" switch >
                    Show Siding 
                </b-form-checkbox>
                <b-form-input v-model="internalSearch" class="fuzeSearchBox ml-2" placeholder="Search within these results..."></b-form-input>
            </b-form>
        </template>
        <b-list-group flush v-if="filteredResults.length < 1">
        <b-list-group-item v-for="material in search" :key="material.material">
            <b-row>
                <b-col cols="12" md="12" class="text-wrap d-inline-block" >
                    <b>{{material.material | capitalizeEachWord}}</b>
                </b-col>
                <b-col cols="12" md="12" class="text-truncate d-inline-block">
                    <b-list-group>
                        <b-list-group-item class="smallJobRow" v-for="job in material.jobs" :key="job._id" :to="'/jobprofile/'+job.job._id">
                            <b-row>
                                <b-col cols="3" lg="2">
                                    <small>{{format(fromUnixTime(job.job.dates.contractDate), 'MM/dd/yy')}}</small>
                                </b-col>
                                <b-col cols="9" lg="5" class="text-truncate d-inline-block">
                                    <small>
                                        {{job.job.customer.lname}}, {{job.job.customer.fname}} | {{job.job.jobType}} | {{job.manufacturer}} {{job.color}}
                                        <span v-if="job.job.jobLabel" class="text-info"><br/>Label: {{job.job.jobLabel}} </span>
                                    </small> 
                                </b-col>
                                <b-col cols="12" lg="4" class="text-truncate d-inline-block">
                                    <small>{{ job.job.siteAddress.street }}, {{ job.job.siteAddress.city }}</small> 
                                </b-col>
                                <b-col cols="12" lg="1" class="text-truncate d-inline-block">
                                    <div class="float-right"><font-awesome-icon icon="fa-chevron-right"></font-awesome-icon></div>
                                </b-col>
                            </b-row>
                        </b-list-group-item>
                    </b-list-group>
                </b-col>
            </b-row>
        </b-list-group-item>
        </b-list-group>

        <b-list-group flush v-show="filteredResults.length > 0">
        <b-list-group-item v-for="material in filteredResults" :key="material.material" >
            <b-row>
                <b-col cols="12" md="12" class="text-wrap d-inline-block" >
                    <b>{{material.material | capitalizeEachWord}}</b>
                </b-col>
                <b-col cols="12" md="12" class="text-truncate d-inline-block">
                    <b-list-group>
                        <b-list-group-item class="smallJobRow" v-for="job in material.jobs" :key="job._id" :to="'/jobprofile/'+job.job._id">
                            <b-row>
                                <b-col cols="3" lg="2">
                                    <small>{{format(fromUnixTime(job.job.dates.contractDate), 'MM/dd/yy')}}</small>
                                </b-col>
                                <b-col cols="9" lg="5" class="text-truncate d-inline-block">
                                    <small>
                                        {{job.job.customer.lname}}, {{job.job.customer.fname}} | {{job.job.jobType}} | {{job.manufacturer}} {{job.color}}
                                        <span v-if="job.job.jobLabel" class="text-info"><br/>Label: {{job.job.jobLabel}} </span>
                                    </small> 
                                </b-col>
                                <b-col cols="12" lg="4" class="text-truncate d-inline-block">
                                    <small>{{ job.job.siteAddress.street }}, {{ job.job.siteAddress.city }}</small> 
                                </b-col>
                                <b-col cols="12" lg="1" class="text-truncate d-inline-block">
                                    <div class="float-right"><font-awesome-icon icon="fa-chevron-right"></font-awesome-icon></div>
                                </b-col>
                                <b-col cols="12" class="">
                                    <small>
                                        <b>Search Matched:</b> {{job.matchedValue.value.slice(0, job.matchedValue.indices[0][0])}}<span style="background-color: yellow;">{{job.matchedValue.value.slice(job.matchedValue.indices[0][0], job.matchedValue.indices[0][1]+1)}}</span>{{job.matchedValue.value.slice(job.matchedValue.indices[0][1]+1)}}

                                        <b>Catagory:</b> {{job.matchedValue.key.slice(job.matchedValue.key.lastIndexOf('.')+1)}}
                                    </small>
                                </b-col>
                            </b-row>
                        </b-list-group-item>
                    </b-list-group>
                </b-col>
            </b-row>
        </b-list-group-item>
        </b-list-group>
    </b-card>

</template>

<script>
import { format } from 'date-fns'
import { fromUnixTime } from 'date-fns'
import Fuse from "fuse.js"


export default {
    name: 'MaterialSearch',
    data() {
        return {
            format,
            fromUnixTime,
            showRoofing: true,
            showSiding: true,
            search: [],
            internalSearch: "",
            fuseOptions: {
                includeScore: true,
                includeMatches: true,
                minMatchCharLength: 3,
                shouldSort: true,
                findAllMatches: true,
                threshold: 0.1,
                keys: [
                    "jobs.material", "jobs.manufacturer", 
                    "jobs.color", "jobs.job.siteAddress.city", 
                    "jobs.job.siteAddress.street", "jobs.job.customer.fname", 
                    "jobs.job.customer.lname" 
                ]
            }
            
        }
    },
    watch: {
        showRoofing: function (){
            if(!this.showRoofing){
                this.search = this.sidingResults
            } else if(this.showRoofing && this.showSiding){
                this.search = this.materialSearch
            } else if(!this.showRoofing && !this.showSiding){
                this.search = []
            } else {
                this.search = this.roofingResults
            }
        },
        showSiding: function (){
            if(!this.showSiding){
                this.search = this.roofingResults
            } else if(this.showRoofing && this.showSiding){
                this.search = this.materialSearch
            } else if(!this.showRoofing && !this.showSiding){
                this.search = []
            } else {
                this.search = this.sidingResults
            }
        }
    },
    props: {
        searchTerm: String,
        materialSearch: Array 
    },
    computed: {
        isMobile() {
            return this.$state.isMobile
        },
        filteredResults() {
            let fuse = new Fuse(this.search, this.fuseOptions)
            let fuseResults = fuse.search(this.internalSearch)
            console.log(fuseResults)

            let massagedResult = []

            for(let i=0;i<fuseResults.length;i++){
                let foundResult = {
                    _id: null,
                    material: "",
                    jobs: []
                }
                foundResult._id = fuseResults[i].item._id
                foundResult.material = fuseResults[i].item.material
                for(let x=0;x<fuseResults[i].matches.length;x++){
                    let matchIndex = fuseResults[i].matches[x].refIndex
                    let matchedJob = fuseResults[i].item.jobs[matchIndex]
                    matchedJob.matchedValue = {
                        value: fuseResults[i].matches[x].value,
                        key: fuseResults[i].matches[x].key,
                        indices: fuseResults[i].matches[x].indices
                    }
                    foundResult.jobs.push(matchedJob)
                }
                massagedResult.push(foundResult)
            }
            return massagedResult
        },
        roofingResults() {
            
            let roofingOnly = []
            //loop thru material Search
            this.materialSearch.forEach(result => {
                
                let thisResult = {
                    id: result.id,
                    material: result.material,
                    jobs: []
                }
                for(let i = 0; i < result.jobs.length; i++) {
                    
                    if(result.jobs[i].job.jobType.toLowerCase() == 'rip reroof' 
                        || result.jobs[i].job.jobType.toLowerCase() == 'new construction roof'
                        || result.jobs[i].job.jobType.toLowerCase() == 'cover reroof'
                        || result.jobs[i].job.jobType.toLowerCase() == 'roof repair'
                        || result.jobs[i].job.jobType.toLowerCase() == 'new construction') {  
                            thisResult.jobs.push(result.jobs[i])
                        }
                }
                if(thisResult.jobs.length > 0){
                    roofingOnly.push(thisResult)
                }
                
            })
            
            return roofingOnly
        },
        sidingResults() {
            
            let sidingOnly = []
            //loop thru materialSearch
            this.materialSearch.forEach(result => {
                
                let thisResult = {
                    id: result.id,
                    material: result.material,
                    jobs: []
                }
                for(let i = 0; i < result.jobs.length; i++) {
                    
                    if(result.jobs[i].job.jobType.toLowerCase() == 'siding' 
                        || result.jobs[i].job.jobType.toLowerCase() == 'siding repair'
                        || result.jobs[i].job.jobType.toLowerCase() == 'trim job') {  
                            thisResult.jobs.push(result.jobs[i])
                        }
                }
                if(thisResult.jobs.length > 0){
                    sidingOnly.push(thisResult)
                }
                
            })
            return sidingOnly
        }
    },
    methods: {
        results(results) {
            console.log(results)
        }
    },
    created() {
        this.search = this.materialSearch
    }
}
</script>

<style lang="scss" scoped>
   .list-group-item .smallJobRow {
        padding: .3rem 1rem
    } 
    .fuzeSearchBox {
        flex-grow: 3;
    }

    @media (max-width: 509px) {
        .form-inline {
            flex-flow: column;
            align-items: flex-start;
        }
    } 
</style>