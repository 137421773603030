<template>
    <b-card :header="`Phone numbers that match '${searchTerm}'`" class="mb-3" no-body border-variant="primary" header-bg-variant="primary" header-text-variant="white">
        <b-list-group flush>
        <b-list-group-item v-for="result in phoneSearch" :key="result.customer._Id">
            <b-row>
                <b-col cols="12" lg="4" class="text-truncate d-inline-block" >
                    {{result.customer.lname}}, {{result.customer.fname}}<br/>
                    {{result.customer.address.street}}<br/>
                    {{result.customer.address.city}}, {{result.customer.address.state}}
                </b-col>
                <b-col cols="12" lg="8" class="text-truncate d-inline-block" >
                    <b-list-group >
                        <b-list-group-item v-for="job in result.jobs" :key="job._id" class="smallJobRow" :to="'/jobProfile/'+job._id">
                        <b-row>
                            <b-col cols="3" md="2">
                                <small>
                                    {{format(fromUnixTime(job.dates.contractDate), 'MM/dd/yy')}}
                                </small> 
                            </b-col>
                            <b-col cols="3" lg="2">
                                <small>
                                    <b-badge v-if="job.sold" variant="primary" class="text-sm">Sold</b-badge>
                                    <b-badge v-else variant="secondary">Proposal</b-badge> <span v-if="job.sold">/</span>
                                    <b-badge v-if="job.sold && job.status === 'pending'" variant="info" class="ml-1">Pending</b-badge>
                                    <b-badge v-if="job.sold && job.status === 'started'" variant="warning" class="ml-1">Started</b-badge>
                                    <b-badge v-if="job.sold && job.status === 'finished'" variant="success" class="ml-1">Done</b-badge>
                                </small>
                            </b-col>
                            <b-col cols="6" lg="2" class="text-truncate d-inline-block text-right">
                                <small>{{job.jobType}}</small>
                            </b-col>
                            <b-col cols="12" lg="6" class="text-truncate d-inline-block">
                                <small>
                                    {{job.siteAddress.street}}, {{job.siteAddress.city}}
                                    <span v-if="job.jobLabel" class="text-info"><br/>Label: {{job.jobLabel}}</span>
                                    <div class="float-right"><font-awesome-icon icon="fa-chevron-right"></font-awesome-icon></div>
                                </small>
                            </b-col>
                        </b-row>
                        </b-list-group-item>
                    </b-list-group>
                </b-col>
            </b-row>
        </b-list-group-item>
        </b-list-group>
    </b-card>

</template>

<script>
import { format } from 'date-fns'
import { fromUnixTime } from 'date-fns'

export default {
    name: 'phoneSearch',
    data() {
        return {
            format,
            fromUnixTime
        }
    },
    props: {
        searchTerm: String,
        phoneSearch: Array 
    },
    computed: {
    },
    methods: {
        
    
    } 
}
</script>

<style lang="scss" scoped>
    .list-group-item .smallJobRow {
        padding: .3rem 1rem
    }
</style>